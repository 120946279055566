.answer-detail-page{
    
    width: 50%;
    margin: auto;
    padding-top: 70px;
    
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.answer-detail-page-top{
    background-color: white;
    padding: 10px;
}
.answer-detail-page-main{
    display: flex;
    flex-direction: column;
    background-color: white;
    
}

@media (max-width: 1048px){
    .answer-detail-page{
        padding-top: 110px;
    }
}