.querybox{
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.querybox-top{

    display: flex;
    justify-content: flex-start;
    gap:10px ;
    margin-top: 10px;
}
.querybox-username{
    width: 30px;
    height: 30px;
    background-color: #7F1D1D;
    border-radius: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; 
    border: 2px solid #3B82F6;
    font-weight: 600;
    font-size: 20px;
    padding-left: 2px;
    padding-bottom: 2px;
    padding-right: 1px;
    margin-left: 10px;
}
.querybox-top-para{
    cursor: pointer;
    padding: 5px;
    background-color: #F1F2F2; 
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 87%;
    font-size: 15px;
}
.querybox-bottom{
    display: flex;
   
    margin-bottom: 10px;
    
    
}
.querybox-bottom-parts{
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    font-size: 12px;
    gap: 5px;  
    padding: 5px;
}

.querybox-bottom-parts:hover {
    background-color: #F1F2F2;
   
  }
  
  .querybox-bottom-parts:last-child {
    border-right: none;
  }